import React, { useContext, useEffect, useState } from 'react'

import { StateController } from './../../App'
import Preloader from './Preloader'

function CounterDown({
  showDays,
  showHours,
  showMins,
  showSegs
}) {
  const {context} = useContext(StateController)
  const [counterDays, setCounterDays] = useState(0)
  const [counterHours, setCounterHours] = useState(0)
  const [counterMins, setCounterMins] = useState(0)
  const [counterSegs, setCounterSegs] = useState(0)
  const [end, setEnd] = useState(false)
  
  useEffect(() => {
    if (context && context.counter && context.counter.value != null) {
      const countDownDate = new Date(context.counter.value).getTime()

      const startCountdown = () => {
        const timer = setInterval(function() {
          let now = new Date().getTime();
          let distance = countDownDate - now;
            
          if (distance < 0) {
            setEnd(true)
            clearInterval(timer)
          }

          let days = Math.floor(distance / (1000 * 60 * 60 * 24));
          let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
          setCounterDays(days)
          setCounterHours(hours)
          setCounterMins(minutes)
          setCounterSegs(seconds)
        }, 1000)
      }
      startCountdown()
    }
  }, [context])

  return (
    <div className={`mc-counter-down`}>
      <div>
        {context
          ? <div id="countdown">
              {end
                ? <>El contador finalizó</>
                : <ul>
                    {showDays && <li><span id="days">{counterDays} Días</span></li>}
                    {showHours && <li><span id="hours">{counterHours} Horas</span></li>}
                    {showMins && <li><span id="minutes">{counterMins}</span>Minutos</li>}
                    {showSegs && <li><span id="seconds">{counterSegs}</span>Segundos</li>}
                  </ul>
              }
              
            </div>
          : <Preloader
              type="section"
              invert
            />
        }
      </div>
    </div>
  )
}

export default CounterDown