import React, { useEffect, useContext, useState, useRef } from 'react'
import { Link } from "react-router-dom"
import { StateController } from './../../App'


import Input from '../atoms/Input'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon'
import FormQuantumData from './FormQuantumData'
import { sendDataLayer } from '../../utils/functions'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import LoadingRedemption from '../atoms/LoadingRedemption'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function CustomModal({
  token,
  show,
  setShowModal,
  primaryAction,
  secondaryAction,
  terms,
  image,
  title,
  formatTitle,
  desc,
  cta,
  twoStepAuth,
  location,
  brand_id,
  quantumName,
  setQuantumName,
  quantumDoc,
  setQuantumDoc,
  department,
  city,
  site,
  setDepartment,
  setCity,
  setSite,
  redemtion,
  awardSelected,
  globalModal,
  link,
  noClose,
  firstToArrive,
  showErrorAuth,
  base64Pdf,
  showSuccessRedemtion,
  reload,
  showSuccessDownload,
  description,
  popUpShakira,
}) {
  const { setMdalOpen } = useContext(StateController)
  const [twoStepAuthStatus, setTwoStepAuthStatus] = useState(false)
  const [expDate, setExpDate] = useState('')
  const [primaryActionStatus, setPrimaryActionStatus] = useState('inactive')
  const [dropDownTyc, setDropDownTyc] = useState(false);
  const [height, setHeight] = useState("0px");
  const contentRef = useRef(null);

  const [errors, setErrors] = useState({
    expDate: false
  })

  useEffect(() => {

    setMdalOpen(show ? true : false)
  }, [show])

  const close = () => {
    if (redemtion) {
      sendDataLayer({ datalayer: { event: 'cancel_bond', product_name: awardSelected?.name, product_price: awardSelected?.price, index: awardSelected?.index, quatum_type: !awardSelected?.location ? 1 : awardSelected?.location === 1 ? 2 : awardSelected?.location === 2 ? 3 : 1 }, repeat: true })
    }
    setShowModal(false)
    setDropDownTyc(false)
    setExpDate('')
  }

  const handleChange = async (e) => {
    const element = e.target.getAttribute('rel')

    if (element === 'birthdate') {
      setExpDate(e.target.value)
      setErrors({ ...errors, expDate: false })
      if (primaryAction.setAuthDate) {
        primaryAction.setAuthDate(e.target.value)
      }

      setTwoStepAuthStatus(true)
    }
  }

  const handleDropdownTyc = () => {
    setDropDownTyc(!dropDownTyc)
    sendDataLayer({datalayer: { event: "bond_tyc",product_name: awardSelected?.name,product_price: awardSelected?.price,index: (awardSelected?.index), quatum_type: !awardSelected?.location ? 1 : awardSelected?.location === 1 ? 2 : awardSelected?.location === 2 ? 3 : 1},repeat: true})
  }

  useEffect(() => {
    if (dropDownTyc) {      
      setHeight(`${contentRef.current.scrollHeight}px`);
    } else {      
      setHeight("0px");
    }
  }, [dropDownTyc]);

  useEffect(() => {

    if (
      (twoStepAuth && twoStepAuthStatus) ||
      (location && location === 1 && quantumName !== '' && quantumDoc !== '') ||
      (location && location === 2 && site?.selected)
    ) {
      setPrimaryActionStatus('active')
    } else {
      setPrimaryActionStatus('inactive')
    }

    if (!twoStepAuth && location === 0) setPrimaryActionStatus('active')

  }, [twoStepAuth, location, quantumName, quantumDoc, site, twoStepAuthStatus])


  useEffect(() => {
    if (firstToArrive && globalModal) {
      sendDataLayer({ datalayer: { event: "first_to_arrive" }, repeat: true })
    }
  }, [])

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [show])
  
  const reloadPage = ()=>{
    if (reload) {
      window.location.href = reload
    }
  }
  return (
    <div className={`mc-custom-modal ${show && 'mc-custom-modal__opened'} slow_ani ${globalModal ? 'globalModal' : ''} ${redemtion ? 'modalRedeem' : ''} ${showSuccessRedemtion ? 'modalSuccess': ''} ${showSuccessDownload ? 'modalSuccessDownload': ''} ${primaryAction?.loading ? 'fullscreen': ''} ${popUpShakira ? 'popUpShakira': ''}`}>      
      <div onClick={close} className={`mc-custom-modal__overlay slow_ani`}></div>
      <div className="mc-custom-modal__center">
        <VerticalCenter className={(redemtion || showSuccessRedemtion || showSuccessDownload) ? 'noCenter' : ''}>
          <div className="mc-custom-modal__center--window slow_ani">

            {
              redemtion ?
              
              // Solo redenciones
                <>                
                  <div className="modal-content">
                    <div className="modal-header">
                      {!noClose &&
                        <div className="mc-custom-modal__close" onClick={close}>
                          <Icon name="close" />
                        </div>
                      }

                      <div className="title-modal">
                        <h3>{!primaryAction.loading ? 'Ya puedes redimir tu bono' : '¡Tu bono se está descargando!'}</h3>
                      </div>
                    </div>


                    {
                      primaryAction.loading ?
                        <div className='loadingBarProgress'>
                          <LoadingRedemption text />
                        </div>
                        :
                        <>
                         <div className="modal-body">
                      {image &&
                        <div
                          className="mc-custom-modal__center--window__image"
                          style={{ "backgroundImage": `url(/assets/images/${image})` }}
                        >
                        </div>
                      }
                      <div className="mc-custom-modal__center--window__desc" dangerouslySetInnerHTML={{ __html: desc }}>
                      </div>
                      <div className="mc-custom-modal__center--window__title">{title}<span>COP</span></div>
                      

                      {twoStepAuth &&
                        <div className="mc-custom-modal__center--window__auth">
                          <h4>Valida tu identidad y accede a tu premio:</h4>
                          <p>Ingresa la siguiente información para iniciar la descarga.</p>

                          <Input
                            type={'birthdate'}
                            label={'Fecha de nacimiento'}
                            placeholder={'DD/MM/AAAA'}
                            onChange={handleChange}
                            inputByCharacter={false}
                            maxlength={10}
                            value={expDate}
                            error={errors.expDate}
                          />
                          {/* <p className='error-validate'>Fecha errónea, por favor verifica la información ingresada y vuelve a intentarlo..</p> */}
                        </div>
                      }

                      {
                        location ?
                          <FormQuantumData
                            token={token}
                            location={location}
                            brand_id={brand_id}
                            quantumName={quantumName}
                            setQuantumName={setQuantumName}
                            quantumDoc={quantumDoc}
                            setQuantumDoc={setQuantumDoc}
                            department={department}
                            city={city}
                            site={site}
                            setDepartment={setDepartment}
                            setCity={setCity}
                            setSite={setSite}
                          />
                          :
                          <></>
                      }
                    </div>
                    <div className="modal-footer">
                      <div className="mc-custom-modal__center--window__actions">
                        {secondaryAction &&
                          <Button
                            type="secondary"
                            text={secondaryAction.buttonText}
                            loading={false}
                            status="active"
                            onClick={secondaryAction.action}
                          />
                        }
                        <Button
                          type="primary"
                          text={primaryAction ? primaryAction.buttonText : cta}
                          loading={primaryAction ? primaryAction.loading : false}
                          status={primaryActionStatus}
                          onClick={primaryAction ? primaryAction.action : close}
                          redemtion={redemtion}
                        />


                      </div>

                      <div className="searchAward">
                        <p><Icon name='folder' /> Busca tu bono en Documentos (IOS) o en Archivos (ANDROID).</p>
                      </div>

                      <div className="tycAwards">
                        <p>Bono sujeto a términos y condiciones del comercio aliado.</p>

                        <div className="dropDownTyx">
                          <h4 onClick={handleDropdownTyc}>Conócelos aquí <span className='icon-dropDownTyx'><Icon name={dropDownTyc ? "arrow-down" : 'faq'} /></span> </h4>
                          <p ref={contentRef} style={{ height: height }} className={`dropDown-content`}>{description}</p>
                        </div>
                      </div>
                    </div>
                        </>
                    }
                   
                  </div>
                </>
                :

                showSuccessRedemtion ?

                <>                
                  <div className="modal-content">
                    <div className="modal-header">
                      {!noClose &&
                        <div className="mc-custom-modal__close" onClick={close}>
                          <Icon name="close" />
                        </div>
                      }

                      <div className="title-modal">
                        <h3>¡Tu bono se ha descargado!</h3>
                      </div>
                      </div>
                      <div className="modal-body">
                      {image &&
                        <div
                          className="mc-custom-modal__center--window__image_succesess"
                          style={{ "backgroundImage": `url(/assets/images/${image})` }}
                        >
                        </div>
                      }
                        <div className='loadingBarProgress loadingBarProgress_success'>
                          <LoadingRedemption />
                        </div>

                        {showSuccessRedemtion && base64Pdf &&
                    <div className="mc-custom-modal__center--window__pdf">
                      <div className="mc-custom-modal__center--window__pdf--canvas" style={{ height: '173px', transformOrigin: 'top center' }}>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                          <Viewer fileUrl={base64Pdf} />
                        </Worker>
                      </div>
                    </div>
                  }
                        
                    </div>
                    <div className="modal-footer">
                      <div className="searchAward">
                        <p><Icon name='folder' /> Busca tu bono en Documentos (IOS) o en Archivos (ANDROID).</p>
                      </div>

                      <div className="mc-custom-modal__center--window__actions" onClick={reloadPage}>
                        {secondaryAction &&
                          <Button
                            type="secondary"
                            text={secondaryAction.buttonText}
                            loading={false}
                            status="active"
                            onClick={secondaryAction.action}
                          />
                        }
                        <Button
                          type="primary"
                          text={primaryAction ? primaryAction.buttonText : cta}
                          loading={primaryAction ? primaryAction.loading : false}
                          status={'active'}
                          onClick={primaryAction ? primaryAction.action : close}
                          redemtion={redemtion}
                        />


                      </div>


                    </div>
                        
                    
                   
                  </div>
                </>

                :

                showSuccessDownload ?
                <>                
                <div className="modal-content">
                  <div className="modal-header">
                    {!noClose &&
                      <div className="mc-custom-modal__close" onClick={close}>
                        <Icon name="close" />
                      </div>
                    }

                    <div className="title-modal">
                      <h3>¡Tu bono se ha descargado!</h3>
                    </div>
                    </div>
                    <div className="modal-body">
                    {image &&
                      <div
                        className="mc-custom-modal__center--window__image_succesess"
                        style={{ "backgroundImage": `url(/assets/images/${image})` }}
                      >
                      </div>
                    }
                      <div className='loadingBarProgress loadingBarProgress_success'>
                        <LoadingRedemption />
                      </div>

                      {showSuccessDownload && base64Pdf &&
                  <div className="mc-custom-modal__center--window__pdf">
                    <div className="mc-custom-modal__center--window__pdf--canvas" style={{ height: '173px', transformOrigin: 'top center' }}>
                      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                        <Viewer fileUrl={base64Pdf} />
                      </Worker>
                    </div>
                  </div>
                }
                      
                  </div>
                  <div className="modal-footer">
                    <div className="searchAward">
                      <p><Icon name='folder' /> Busca tu bono en Documentos (IOS) o en Archivos (ANDROID).</p>
                    </div>

                    <div className="mc-custom-modal__center--window__actions" onClick={reloadPage}>
                      {secondaryAction &&
                        <Button
                          type="secondary"
                          text={secondaryAction.buttonText}
                          loading={false}
                          status="active"
                          onClick={secondaryAction.action}
                        />
                      }
                      <Button
                        type="primary"
                        text={primaryAction ? primaryAction.buttonText : cta}
                        loading={primaryAction ? primaryAction.loading : false}
                        status={'active'}
                        onClick={primaryAction ? primaryAction.action : close}
                        redemtion={redemtion}
                      />


                    </div>


                  </div>
                      
                  
                 
                </div>
              </>
                :
                popUpShakira ?

                <>
                {!noClose &&
                  <div className="mc-custom-modal__close" onClick={close}>
                    <Icon name="close" />
                  </div>
                }

                {image &&
                  <div
                    className="mc-custom-modal__center--window__image"
                    style={{ "backgroundImage": `url(/assets/images/${image})` }}
                  >
                  </div>
                }
                {globalModal &&
                  <img src={`/assets/images/${image}`} className="global-icons" />
                }
                <div className="mc-custom-modal__center--window__title">{title}</div>
                <div className="mc-custom-modal__center--window__desc">
                  <div dangerouslySetInnerHTML={{ __html: desc }} />
                </div>


                {/* <div className="mc-custom-modal__center--window__actions">
                  {globalModal
                    ?
                    <Button
                      type="primary"
                      text={primaryAction ? primaryAction.buttonText : cta}
                      loading={primaryAction ? primaryAction.loading : false}
                      status={'active'}
                      link={link}
                    />
                    : <Button
                      type="primary"
                      text={primaryAction ? primaryAction.buttonText : cta}
                      loading={primaryAction ? primaryAction.loading : false}
                      status={'active'}
                      onClick={primaryAction ? primaryAction.action : close}
                    />
                  }

                  {secondaryAction &&
                    <Button
                      type="secondary"
                      text={secondaryAction.buttonText}
                      loading={false}
                      status="active"
                      onClick={secondaryAction.action}
                    />
                  }
                </div> */}

                {terms &&
                  <div className="mc-custom-modal__center--window__terms">
                    <Link to={terms.termsLink}>
                      {terms.termsText}
                    </Link>
                  </div>
                }
              </>
              :
                // Generales
                <>
                  {!noClose &&
                    <div className="mc-custom-modal__close" onClick={close}>
                      <Icon name="close" />
                    </div>
                  }

                  {image &&
                    <div
                      className="mc-custom-modal__center--window__image"
                      style={{ "backgroundImage": `url(/assets/images/${image})` }}
                    >
                    </div>
                  }
                  {globalModal &&
                    <img src={`/assets/images/${image}`} className="global-icons" />
                  }
                  {formatTitle
                    ? <div className="mc-custom-modal__center--window__title" dangerouslySetInnerHTML={{ __html: title }} />
                    : <div className="mc-custom-modal__center--window__title">{title}</div>
                  }
                  <div className="mc-custom-modal__center--window__desc">
                    <div dangerouslySetInnerHTML={{ __html: desc }} />
                  </div>

                  {twoStepAuth &&
                    <div className="mc-custom-modal__center--window__auth">
                      <p>Doble autenticación</p>

                      <Input
                        type={'birthdate'}
                        label={'Fecha de expedición de su cédula'}
                        placeholder={'DD/MM/AAAA'}
                        onChange={handleChange}
                        inputByCharacter={false}
                        maxlength={10}
                        value={expDate}
                        error={errors.expDate}
                      />
                      {showErrorAuth &&
                    <div className="mc-custom-modal__center--window__auth--error">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                        <path d="M6.5 13.22C9.8 13.22 12.5 10.52 12.5 7.21997C12.5 3.91997 9.8 1.21997 6.5 1.21997C3.2 1.21997 0.5 3.91997 0.5 7.21997C0.5 10.52 3.2 13.22 6.5 13.22Z" stroke="#A00104" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.5 9.03144L8.31151 5.21997" stroke="#A00104" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8.31151 9.03144L4.5 5.21997" stroke="#A00104" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      Fecha errónea, por favor verifica la información ingresada y vuelve a intentarlo.
                    </div>
                  }
                    </div>
                  }

                  {
                    location ?
                      <FormQuantumData
                        token={token}
                        location={location}
                        brand_id={brand_id}
                        quantumName={quantumName}
                        setQuantumName={setQuantumName}
                        quantumDoc={quantumDoc}
                        setQuantumDoc={setQuantumDoc}
                        department={department}
                        city={city}
                        site={site}
                        setDepartment={setDepartment}
                        setCity={setCity}
                        setSite={setSite}
                      />
                      :
                      <></>
                  }

                  <div className="mc-custom-modal__center--window__actions">
                    {globalModal
                      ?
                      <Button
                        type="primary"
                        text={primaryAction ? primaryAction.buttonText : cta}
                        loading={primaryAction ? primaryAction.loading : false}
                        status={'active'}
                        link={link}
                        onClick={formatTitle ? close : false}
                      />
                      : <Button
                        type="primary"
                        text={primaryAction ? primaryAction.buttonText : cta}
                        loading={primaryAction ? primaryAction.loading : false}
                        status={'active'}
                        onClick={primaryAction ? primaryAction.action : close}
                      />
                    }

                    {secondaryAction &&
                      <Button
                        type="secondary"
                        text={secondaryAction.buttonText}
                        loading={false}
                        status="active"
                        onClick={secondaryAction.action}
                      />
                    }
                  </div>
                  {terms &&
                    <div className="mc-custom-modal__center--window__terms">
                      <Link to={terms.termsLink}>
                        {terms.termsText}
                      </Link>
                    </div>
                  }
                </>
            }

          </div>
        </VerticalCenter>
      </div>
    </div>
  )
}

export default CustomModal