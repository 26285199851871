import React, { useEffect, useState, createContext } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useSearchParams,
  useNavigate,
  Link
} from 'react-router-dom'
import { connect } from 'react-redux'

// pages
import Login from "./components/pages/Login"
import Progress from "./components/pages/Progress"
import Mechanic from "./components/pages/Mechanic"
import Terms from "./components/pages/Terms"
import Faqs from "./components/pages/Faqs"
import Dashboard from "./components/pages/Dashboard"
import NotFound from "./components/pages/NotFound"
import Awards from "./components/pages/Awards"
import Alliances from "./components/pages/Alliances"
import Redemptions from "./components/pages/Redemptions"
import CounterDown from './components/atoms/CounterDown'

// layout
import Header from "./layouts/Header/Header"
import Footer from "./layouts/Footer/Footer"

// components
import CustomModal from './components/molecules/CustomModal'

// actions
import {
  logOutAction,
  loginShaAction
} from './store/sessionDuck'

import {
  gtUser,
  gtStaticContent
} from './utils/helpers'
import { getPrice, sendDataLayer, getLevel, getTextTypeCard } from './utils/functions'
import { doRedeem } from './utils/helpers'

import './styles/main.scss'

export const StateController = createContext(null)

function App({
  error,
  token,
  loggedIn,
  logOutAction
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [modalOpen, setMdalOpen] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [context, setContext] = useState(false)
  const [staticContent, setStaticContent] = useState(false)
  const [autologinPreloader, setAutologinPreloader] = useState(false)  
  
  const [awardSelected, setAwardSelected] = useState({})
  const [showConfirm, setShowConfirm] = useState(false)
  const [redemtionLoading, setRedemtionLoading] = useState(false)
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false)
  const [showSuccessRedemtion, setShowSuccessRedemtion] = useState(false)
  const [quantumName, setQuantumName] = useState('')
	const [quantumDoc, setQuantumDoc] = useState('')
  const [department, setDepartment] = useState(false)
  const [city, setCity] = useState(false)
  const [site, setSite] = useState(false)
  const [showErrorAuth, setShowErrorAuth] = useState(false)
  const [authDate, setAuthDate] = useState('')
  const [base64Pdf, setBase64Pdf] = useState(false)

  const [rouletteWinner, setRouletteWinner] = useState(false)
  const [hasRouletteAward, setHasRouletteAward] = useState(false)
  const [alreadyRunner, setAlreadyRunner] = useState(false)
  const [show70Push, setShow70Push] = useState(false)
  const [preSecondOportunity, setPreSecondOportunity] = useState(false)
  const [preSecondOportunityMoment2, setPreSecondOportunityMoment2] = useState(false)
  
  const home_page = '/progreso'
  const pages = window.innerWidth > 992
    ? [
        {page: 'progreso', title: 'Progreso', icon: 'progress', sublevel: false, component: <Progress privatePage />},
        {page: 'mecanica', title: '¿Cómo ganar?', icon: 'mechanic', sublevel: false, component: <Mechanic privatePage />},
        {page: 'premios', title: 'Catálogo de premios', icon: 'awards', sublevel: false, component: <Awards privatePage />},
        {page: 'mis-redenciones', title: 'Premios redimidos', icon: 'icon', sublevel: false, component: <Redemptions privatePage />},
        {page: 'alianzas', title: 'Alianzas', icon: 'icon', sublevel: false, component: <Alliances privatePage />},
        {page: 'mas', title: 'Otros ', icon: 'more', sublevel: true, children: [
          {page: 'terminos-y-condiciones', title: 'Términos y condiciones', component: <Terms />},
          {page: 'preguntas-frecuentes', title: 'Preguntas frecuentes', component: <Faqs />}
        ]}
      ]
    : [
      {page: 'progreso', title: 'Progreso', icon: 'progress', sublevel: false, component: <Progress privatePage />},
      {page: 'mecanica', title: '¿Cómo ganar?', icon: 'mechanic', sublevel: false, component: <Mechanic privatePage />},
      {page: 'premios', title: 'Catálogo de premios', icon: 'awards', sublevel: false, component: <Awards privatePage />},
      {page: 'mas', title: 'Alianzas y otros ', icon: 'more', sublevel: true, children: [
        {page: 'mis-redenciones', title: 'Premios redimidos', icon: 'icon', component: <Redemptions privatePage />},
        {page: 'alianzas', title: 'Alianzas', icon: 'icon', component: <Alliances privatePage />},
        {page: 'terminos-y-condiciones', title: 'Términos y condiciones', component: <Terms />},
        {page: 'preguntas-frecuentes', title: 'Preguntas frecuentes', component: <Faqs />}
      ]}
    ]

  const closeLoginModal = () => {
    setAutologinPreloader(false)
    setNotFound(false)
    localStorage.removeItem('userNotFound')
    window.location.href = '/'
  }

  useEffect(() => {
    if (localStorage.getItem('userNotFound')) {
      setNotFound(true)

      setTimeout( () => {
        localStorage.removeItem('userNotFound')
      }, 3000 )
    } else {
      setNotFound(false)
    }
  }, [location])

  useEffect(() => {
    if (searchParams.get('utm_web')) {
      setAutologinPreloader(true)      
    }

    if (!localStorage.getItem('storage')) {
      if (window.location.search !== '') {
        console.log("Navegate to:", window.location.search)
        // navigate(window.location.search)
      } else {
        const public_url = pages.find((page) => page.page === location.pathname.replace('/', '') )
        const public_children_urls = pages.find((page) => page.sublevel)


        if (public_url) {
          if (!loggedIn && public_url?.component?.props?.privatePage) document.location.href = '/'

        } else if (public_children_urls) {
          const public_child_url = public_children_urls?.children.find((childPage) => childPage.page === location.pathname.replace('/', '') )

          if (public_child_url?.component?.props?.privatePage) {
            document.location.href = '/'
          }
        }
      }
    }
  }, [searchParams, error])

  useEffect(() => {

    if (token !== '') {
      gtUser(token).then((user) => {
        console.log('user',user)
        
        if (user?.data) {
          setContext(user.data)

          if (window.location.pathname !== home_page && window.location.pathname === '/') {
            window.location.href = home_page
          }
          if (user?.data?.tracing && user?.data?.tracing[`winner_${user.data.block}`] === 1) {
            // sendDataLayer({datalayer: { event: 'goal' },repeat: false})
          }


          if (user.data.tracing && user.data.tracing[`winner_extra_${user.data.block}`]) {
            console.log("Ganador ruleta!")
            setRouletteWinner(true)
          }

          const hasRoulette = user.data.redemtions.find((item) => item.block === 3)
          if (hasRoulette) setHasRouletteAward(true)

          if (localStorage.getItem('rouletteSaved')) {
            setAlreadyRunner(
              JSON.parse(localStorage.getItem('rouletteSaved'))
            )
          }

          if (user?.data?.tracing && user?.data?.tracing.extra2 === "1" && !user?.data?.aditional_info?.status?.pre_block) {
            setShow70Push(true)
          }

          if (user?.data?.aditional_info?.status?.pre_block) {

            const redemtionsBlock1 = user?.data?.redemtions.find((redemtion) => redemtion.block = 1);
            if (!redemtionsBlock1) {
              setPreSecondOportunity(true)
            }
            
            if (
              user?.data?.awards[`block_1_level_${getLevel(user?.data)}`]?.canRedem &&
              user?.data?.tracing[`winner_1_level_${getLevel(user?.data)}`]
            ) {
              setPreSecondOportunityMoment2(true)
            }
          }


        } else if (user.statusCode === 301 && localStorage.getItem('storage') && window.location.pathname === '/') {
          localStorage.removeItem('storage')
          window.location.reload()
        }
        if (user?.statusCode === 301 && window.location.pathname !== '/' && window.location.pathname !== '/dashboard') {
          window.location.href = '/'
        }
      }).catch(() =>  navigate('/'))
    }
  }, [token])

  useEffect(() => {

    gtStaticContent().then((content) => {
      if (content && content.content) setStaticContent(content.content)
    })
  }, [])

  const handlerModal = () => {
    if (showConfirm) {
      sendDataLayer({datalayer: {event: 'cancel_bond',product_name: awardSelected?.name ,product_price: awardSelected?.price, index: awardSelected?.index, quatum_type: !awardSelected?.location ? 1 : awardSelected?.location === 1 ? 2 : awardSelected?.location === 2 ? 3 : 1},repeat: true})
    }
    setShowConfirm(!showConfirm)

  }

  const handlerRedemtion = async () => {
    setRedemtionLoading(true)
    let validator = true
    if (awardSelected.twoStepAuth) {
      console.log("Validar aqui si la fecha es correcta")
      validator = false;
    }
    setShowErrorAuth(false)


    if (awardSelected.canRedem) {
      if (validator) {
        console.log("Redention info:", awardSelected)
        doRedeem(
          token, 
          awardSelected.price, 
          awardSelected.id, 
          quantumName, 
          quantumDoc, 
          site?.selected, 
          authDate, 
          awardSelected.isSaved ? 1 : getLevel(context),
          awardSelected.isRouletteRedemtion
        ).then((data) => {
          setRedemtionLoading(false)
          setShowConfirm(false)
  
          if (data && data.status === 200 && data.file) {
            setShowSuccessRedemtion(true)
            setBase64Pdf(data.base64)
            sendDataLayer({datalayer: { event: "redeem_bond",product_name: awardSelected?.name,product_price: awardSelected?.price,index: awardSelected?.index, quatum_type: !awardSelected?.location ? 1 : awardSelected?.location === 1 ? 2 : awardSelected?.location === 2 ? 3 : 1}, repeat: false})

  
            setTimeout( () => {
              window.location.href = '/mis-redenciones'
            }, 5000 )
          } else {

            console.log("Error redemtion!", data)

            if (data.status === 304) {
              setShowConfirm(true)
              setShowErrorAuth(true)
            } else {
              setShowErrorAuth(false)
              sendDataLayer({datalayer: {event: 'exhausted_bond', product_name: awardSelected?.name, product_price: awardSelected?.price, index: awardSelected?.index, quatum_type: !awardSelected?.location ? 1 : awardSelected?.location === 1 ? 2 : awardSelected?.location === 2 ? 3 : 1},repeat: true})                        
              setShowErrorRedemtion(true)
            }

          }
        }).catch(error => {
          console.log("ERROR ON REDENTION:", error)
        })
      } else {
        setRedemtionLoading(false)
        setShowConfirm(false)
        setShowErrorRedemtion(true)
        console.log("Validacion no completada.")
      }
    } else {
      setRedemtionLoading(false)
      setShowConfirm(false)
      setShowErrorRedemtion(true)
    }
  }

  const footerLinks = window.innerWidth > 992
    ? [
        // {
        //   link: "/mecanica",
        //   text: "¿Cómo ganar?"
        // },
        {
          link: "/terminos-y-condiciones",
          text: "Términos y condiciones"
        },
        {
          link: "/preguntas-frecuentes",
          text: "Preguntas frecuentes"
        }
      ]
    : [
      {
        link: "/terminos-y-condiciones",
        text: "Términos y condiciones"
      },
      {
        link: "/preguntas-frecuentes",
        text: "Preguntas frecuentes"
      }
    ]


  return (
    <StateController.Provider
      value = {
        {
          setMdalOpen: setMdalOpen,
          context: context,
          staticContent: staticContent,
          pages: pages,
          awardSelected: awardSelected,
          setAwardSelected: setAwardSelected,
          handlerRootModal: handlerModal
        }
      }
    >
      <CustomModal
        title="Datos incorrectos"
        desc="Verifica tu información y vuelve a intentarlo."
        cta="Aceptar"
        icon={false}
        primaryAction={
          {
            action: closeLoginModal,
            buttonText: 'Cerrar',
            loading: false
          }
        }
        show={notFound} setShowModal={setNotFound}
      />

      <CustomModal
        show={showConfirm}
        setShowModal={setShowConfirm}
        redemtion={true}
        image={awardSelected.image}
        title={`${getPrice(awardSelected.price)}`}
        desc={awardSelected.name}
        twoStepAuth={false}
        location={awardSelected?.location}
        brand_id={awardSelected?.brand_id}
        description={awardSelected?.description}
        quantumName={quantumName}
        quantumDoc={quantumDoc}
        setQuantumName={setQuantumName}
        setQuantumDoc={setQuantumDoc}
        department={department}
        city={city}
        site={site}
        setDepartment={setDepartment}
        setCity={setCity}
        setSite={setSite}
        token={token}
        awardSelected={awardSelected}
        showErrorAuth={showErrorAuth}
        primaryAction={
          {
            action: handlerRedemtion,
            buttonText: "Descargar",
            loading: redemtionLoading,
            setAuthDate: setAuthDate
          }
        }
        secondaryAction={
          {
            action: handlerModal,
            buttonText: "Cancelar"
          }
        }
        terms={
          {
            termsText: "Términos y condiciones",
            termsLink: "/terminos-y-condiciones"
          }
        }
      />
      <CustomModal
        title="Error en la redención"
        desc={'Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde.'}
        cta="Aceptar"
        show={showErrorRedemtion} setShowModal={setShowErrorRedemtion}
      />
      <CustomModal
        title="Redención correcta"
        desc={`Tu bono ha sido descargado. Podrás encontrarlo en la sección <strong>Redenciones</strong>.`}
        cta="Aceptar"
        image="succesess.png"
        base64Pdf={base64Pdf}
        reload={'/mis-redenciones'}
        showSuccessRedemtion={showSuccessRedemtion}
        show={showSuccessRedemtion} setShowModal={setShowSuccessRedemtion}
      />

      {show70Push &&
        <div className='topbar-container'>
          <div>
            <strong>¡Tus compras por internet sumarán el doble para tu meta principal!</strong> < br />
            Aprovecha del <strong>1 al 15 de noviembre</strong> 🚀
          </div>
        </div>
      }

      {preSecondOportunity &&
        <div className='topbar-container'>
          {preSecondOportunityMoment2
            ? <div className='font-16'>
                <strong>¡Ganaste!</strong> Lograste un progreso de <strong>{getPrice(context?.tracing?.amount_1)}</strong>. Tienes <strong><CounterDown showDays showHours /> para redimir tu bono.</strong> < br/>Si la ruleta está disponible, gírala para seguir ganando. <Link to="/premios">Redime ya</Link>
              </div>
            : (context?.stage == 1)
              ? <div>
                  Tu progreso anterior fue de <strong>{getPrice(context?.tracing?.amount_1)}</strong>, pronto sabrás si ganaste en la primera oportunidad.
                </div>
              : <div>
                  <strong className='font-16'>Se agotó el tiempo para redimir el bono de la primera oportunidad, ¡participa para ganar en la segunda oportunidad! 🎁.</strong>
                </div>
          }
        </div>
      }

      <div className={`App ${modalOpen ? 'modalOpen' : ''} ${loggedIn ? 'loggedIn' : 'noLoggedIn'} ${autologinPreloader ? 'showing-modal' : ''} ${show70Push ? 'with-topbar' : ''}`}>
        <Header
          setShowConfirm={setShowConfirm}
          headerType={1}
          logo="logo.png"
          rouletteWinner={rouletteWinner}
          hasRouletteAward={hasRouletteAward}
          alreadyRunner={alreadyRunner}
          items={pages}
          submenu={{
            type: "nickname",
            default: "A"
          }}
          notifications={
            [
              {
                id: 1,
                title: "Notification",
                content: "html content",
                status: 0
              }
            ]
          }
          loggedIn={loggedIn}
          logOutAction={logOutAction}
          page={location.pathname.replace(/\//g,"")}
        />

        <Routes>
          <Route path="/" element={<Login autologin={autologinPreloader} autologinPreloader={autologinPreloader} setAutologinPreloader={setAutologinPreloader} hash={searchParams.get('utm_web')} />} />

          {/* Root pages */}
          {pages.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}

          {/* Child pages */}
          {pages.find((page) => {
            return page.sublevel ? true : false
          })?.children.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}
          <Route path="/dashboard" element={<Dashboard adminPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer
          footerType={5}
          footerCardsImg="footer_cards.png"
          secondaryFooterImg={!context ? 'footer_cards_all_segments.png' : getTextTypeCard(context) === 'Débito' ? 'footer_cards_debito.png' : getTextTypeCard(context) === 'Crédito' ? 'footer_cards_credito.png': getTextTypeCard(context) === 'Débito y Crédito' ? 'footer_cards_all_segments.png':'footer_cards_all_segments.png'}
          bankFooterImg="bank-footer.png"
          links={footerLinks}
          linksText="Esta es una campaña oficial del Banco AV Villas."
          content="<p>Banco Comercial AV Villas S.A. establecimiento bancario.</p><p>*Válido desde el 16 de noviembre de 2024 hasta el 15 de enero de 2025 y solo aplica para territorio colombiano. Recuerda que, por tu seguridad, Banco Comercial AV Villas S.A. establecimiento bancario nunca solicita a través de este medio información confidencial o financiera como usuarios y clave de acceso a nuestros canales, ni números de productos como cuentas, números de tarjetas de crédito o similares.</p><p>El diseño de los círculos de Mastercard es una marca registrada de Mastercard International Incorporated.</p>"
          copyright="© Copyright 2024"
          extra="Esta es una campaña oficial del Banco AV Villas."
        />

      </div>
    </StateController.Provider>
  )
}

const mapState = ({ user: { fetching, loggedIn, token, error } }) => {
  return {
    fetching,
    loggedIn,
    token,
    error
  }
}

export default connect(mapState, { logOutAction, loginShaAction })(App)