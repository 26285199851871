import React, { useState } from 'react'
import { Link } from "react-router-dom"

import AlliedBox from '../molecules/AlliedBox'

function AlliancesGallery({
  alliedBoxType,
  itemsPerLine,
  limit,
  termsOnModal,
  limitButtonText,
  list,
  termText,
  termLink
}) {
  const [active, setActive] = useState('Alimentos y bebidas')

  const changeCategory = (cat) => {
    setActive(cat)
  }

  return (
    <div className={`mc-alliances-container mc-alliances-column-${itemsPerLine}`}>

      <div className={`alliances-nav`}>
        <div className={active === 'Alimentos y bebidas' ? 'active' : ''} onClick={() => changeCategory('Alimentos y bebidas')}>Alimentos y bebidas</div>
        <div className={active === 'Hogar' ? 'active': ''} onClick={() => changeCategory('Hogar')}>Hogar</div>
        <div className={active === 'Salud y belleza' ? 'active': ''} onClick={() => changeCategory('Salud y belleza')}>Salud y belleza</div>
        <div className={active === 'Servicios' ? 'active': ''} onClick={() => changeCategory('Servicios')}>Servicios</div>
        <div className={active === '0% de tasa' ? 'active': ''} onClick={() => changeCategory('0% de tasa')}>0% de tasa</div>
        <div className={active === 'Viajes' ? 'active': ''} onClick={() => changeCategory('Viajes')}>Viajes</div>
        <div className={active === 'Entretenimiento' ? 'active': ''} onClick={() => changeCategory('Entretenimiento')}>Entretenimiento</div>
      </div>
      
      {list &&
        <div className="mc-alliances-container__items">
          {list.filter((item, i) => {

            if (item.sub_description === active) {
              return true
            } else {
              return false
            }
          }).map((item, index) => {

            return (
              <div key={index} className={`mc-alliances-container__items--item type-${alliedBoxType} type-${item.activate.replace('0','')}`}>
                <AlliedBox
                  type={alliedBoxType}
                  allied={item}
                  termLink={termLink}
                  termText={termText}
                  termsOnModal={termsOnModal}
                  termsData={item.termsData}
                />
              </div>
            )
          })}
        </div>
      }

      {limit && limitButtonText &&
        <div className="mc-alliances-container__actions">
          <Link to="/alianzas">{limitButtonText}</Link>
        </div>
      }

    </div>
  )
}

export default AlliancesGallery