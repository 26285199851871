import React, { useState, useContext } from 'react'
import { connect } from 'react-redux'
import CustomModal from './CustomModal'
import Button from '../atoms/Button'
import Tag from '../atoms/Tag'

import { getPrice, sendDataLayer } from '../../utils/functions'
import { doloadRedeem } from '../../utils/helpers'
import { StateController } from './../../App'

function AwardBox({
  index,
  type,
  award,
  canRedem,
  termLink,
  termText,
  redemtionText,
  showStock,
  token,
  download,
  twoStepAuth,
  setShowSuccessDownload,
  setBase64Pdf,
  isRouletteRedemtion,
  isSaved
}) {
  const {
    setAwardSelected,
    handlerRootModal,
  } = useContext(StateController)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [showErrorDownload, setShowErrorDownload] = useState(false)
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false)  

  const handlerModal = () => {
    setInterval(() => {
      document.querySelector('.mc-fullscreen-modal.roulette')?.remove()
    }, 500);

    if (!download) {
      sendDataLayer({datalayer: { event: "bond_interest",product_name: award?.name,product_price: award?.price,index: (index+1), quatum_type: !award?.location ? 1 : award?.location === 1 ? 2 : award?.location === 2 ? 3 : 1},repeat: true})
    }

    if (download && !downloadLoading) {
      console.log("Redencion:", award)
      setDownloadLoading(true)

      doloadRedeem(award.id, token).then((data) => {
        console.log("result download:", data)
        setDownloadLoading(false)
        sendDataLayer({datalayer: {event: 'redownload',product_name: award?.name ,product_price: award?.price},repeat: true})
        setShowSuccessDownload(true)
        if (data && data.file) {
          setBase64Pdf(data.base64)
          
        }

        if (data && (data.status === 402 || data.status === 400)) {
          setShowErrorDownload(true)
        }
      }).catch(error => {
        console.log("ERROR DOWNLOAD REDENTION:", error)
        setDownloadLoading(false)
        setShowErrorDownload(true)
        setShowSuccessDownload(false)
      })
    } else {

      if (twoStepAuth) {
        console.log("Open Two Step Auth Factor.")
      } else {
        if (canRedem) {
          handlerRootModal()
          console.log("award", award)
          setAwardSelected({
            image: award.image,
            description: award.description,
            price: award.price,
            name: award.name,
            canRedem: canRedem,
            id: award.id,
            location: award.location,
            brand_id: award.idbrand,            
            index: (index + 1),
            isRouletteRedemtion: isRouletteRedemtion,
            isSaved: isSaved
          })
        }
      }

    }
  }

  return (
    <div className='mc-awards-container__items--item__container'>

      <CustomModal
        title="Error en la descarga"
        desc={'Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde.'}
        cta="Aceptar"
        image="image.png"
        show={showErrorDownload} setShowModal={setShowErrorDownload}
      />
      <CustomModal
        title="Error en la redención"
        desc={'Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde.'}
        cta="Aceptar"
        show={showErrorRedemtion} setShowModal={setShowErrorRedemtion}
      />

      {award?.prepurchased && !download && <Tag text="Precompra" />}
      {showStock && <Tag position={"left"} text={`${award.stock} Disponible${award.stock > 1 ? 's' : ''}`} />}
      {type === 1 &&
          <>
            <div className="rowAwards">
              <div className="contentAwards">
                <div className="logos">
                  <div className="mc-awards-container__items--item__image">
                  <img src={`/assets/images/${award?.image}`} alt={award?.name} />
                  </div>
                </div>

                <div className="infoAwards">
                  <p className='valueAward'><strong>Bono por:</strong></p>
                  <div className="mc-awards-container__items--item__price">{getPrice(award?.price)} <span>COP</span></div>
                  <p className='storeAward'>
                    {/* <strong>Bono en tienda</strong> */}
                  </p>
                </div>
              </div>

              <div className="bg-awards">
                <img src={`/assets/images/${award?.image_secondary}`} alt={award?.name} />
              </div>
            </div>

            <div className="description">Aplican términos y condiciones</div>
            <div className="buttonAward">
              <Button
                type="primary"
                text={redemtionText}
                loading={false}
                status={`${canRedem ? 'active' : 'inactive'}`} 
                onClick={(e) => handlerModal(e,)}
              />
            </div>
          </>
      }
      {type === 2 &&
          <>
            <div className="mc-awards-container__items--item__info">
              <div 
                className="mc-awards-container__items--item__image"
                style={{"backgroundImage": `url(/assets/images/${award?.image})`}}
              ></div>
              <div className="mc-awards-container__items--item__info">
                <div className="mc-awards-container__items--item__name">{award?.name}</div>
                <div className="mc-awards-container__items--item__price">COP {award?.price}</div>
              </div>
            </div>
            {termLink &&
              <p>
                <a href={termLink}>
                  {termText}
                </a>
              </p>
            }
            <Button
              type="primary"
              text={redemtionText}
              loading={false}
              status="active"
              onClick={(e) => handlerModal(e,)}
            />
          </>
      }
      {type === 3 &&
          <>
            <div className="mc-awards-container__items--item__info">
              <div 
                className="mc-awards-container__items--item__image"
                style={{"backgroundImage": `url(/assets/images/${award?.image})`}}
              ></div>
              <div className="mc-awards-container__items--item__labels">
                <div className="mc-awards-container__items--item__name">{award?.name}</div>
                <div className="mc-awards-container__items--item__price">COP {award?.price}</div>
              </div>
            </div>
            <div className='mc-awards-container__items--item__actions'>
              {termLink &&
                <p>
                  <a href={termLink}>
                    {termText}
                  </a>
                </p>
              }
              <Button
                type="primary"
                text={redemtionText}
                loading={false}
                status="active"
                onClick={(e) => handlerModal(e,)}
              />
            </div>
          </>
      }
      {type === 4 &&
          <>
            <div className="mc-awards-container__items--item__info">
              <div className="mc-awards-container__items--item__image">
                <span style={{"backgroundImage": `url(/assets/images/${award?.image})`}}></span>
              </div>
              <div className="mc-awards-container__items--item__name">{award?.name}</div>
              <div className="mc-awards-container__items--item__price">COP {award?.price}</div>
              <Button
                type="primary"
                text={redemtionText}
                loading={false}
                status="active"
                onClick={(e) => handlerModal(e,)}
              />
              {termLink &&
                <p>
                  <a href={termLink}>
                    {termText}
                  </a>
                </p>
              }
            </div>
          </>
      }
    </div>
  )
}

const mapState = ({ user: { token } }) => {

  return {
    token
  }
}

export default connect(mapState)(AwardBox)