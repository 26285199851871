import React, { useContext } from 'react'
import { getTextTypeCard } from '../../utils/functions'
import { StateController } from '../../App'

function DynamicBanner({
  push70,
  preSecondOportunity
}) {
  const {context} = useContext(StateController)
  
  return (
    <div className={`mc-dynamic-banner-container`}>

      {push70 && preSecondOportunity 
        ? <img src={`/assets/images/banner-70${window.innerWidth > 992 ? '-desktop':''}.png`} alt="" />
        : <img src={`/assets/images/dynamic-banner${window.innerWidth > 992 ? '-desktop':''}.png`} alt="" />
      }

      {push70 && preSecondOportunity 
        ? <div className='mc-dynamic-banner-container__labels push70'>
            <h2>¿Compras que suman el doble para tu meta?</h2>
            <p>Claro, las compras por internet realizadas del <strong>1 al 15 de noviembre. ¡Aprovecha ya!</strong></p>
          </div>
        : <div className='mc-dynamic-banner-container__labels'>
            <h2>Tarjetas {getTextTypeCard(context)} Mastercard <br className='no-Desktop' />AV Villas</h2>
            <p><strong>Paga con Mastercard</strong> y disfruta beneficios exclusivos para tu día a día.</p>
          </div>
      }
    </div>
  )
}

export default DynamicBanner