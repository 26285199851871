import { sendDataLayer } from '../../utils/functions'
import React, { useState } from 'react'
import CustomModal from './CustomModal'

function AlliedBox({
  type,
  allied,
  termLink,
  termText,
  termsOnModal,
  termsData
}) {
  const [showOnModal, setShowOnModal] = useState(false)
  const dataLayerAllied =(content,page)=>{
    sendDataLayer({datalayer: {event: "alliances",text: content?.name,link: page},repeat: true})  
  }
  const modalToggler = () => {
    setShowOnModal(!showOnModal)
  }

  console.log('Allied', allied)
  
  return (
    <div className={`mc-alliances-container__items--item__container ${termsOnModal ? 'terms' : ''}`}>

      <div className={`tooltip ${allied.activate.replace('0','')}`}>
        Con {allied.activate === 'debitocredito' ? 'Tarjeta Débito y Crédito' : allied.activate === '0credito' ? 'Tarjeta Crédito' : 'Tarjeta Débito'}
      </div>

      {type === 1 &&
        <>
          <div className="mc-alliances-container__items--item__image">
            <span style={{"backgroundImage": `url(/assets/images/${allied.image})`}}>
              <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
            </span>
          </div>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__title">{allied.name}</div>
            <div className="mc-alliances-container__items--item__dtoImg">
              <img src={`/assets/dto/${allied?.img_rule}`} alt="Descuento" />
            </div>

              <div className="mc-alliances-container__items--item__desc" dangerouslySetInnerHTML={{ __html: allied.description }} />
        
            {termLink &&
              termsOnModal
                ? <button className='mc-alliances-button' onClick={modalToggler}>
                    {termText}
                  </button>
                : <a onClick={() =>dataLayerAllied(allied,allied?.link)} href={allied?.link}>
                    {termText}
                  </a>
            }
          </div>
        </>
      }
      {type === 2 &&
        <>
          <div className="mc-alliances-container__items--item__image">
            <span style={{"backgroundImage": `url(/assets/images/${allied.image})`}}>
              <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
            </span>
          </div>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__rule">{allied.rule}</div>
            <div className="mc-alliances-container__items--item__title">{allied.name}</div>
          </div>
          <div className="mc-alliances-container__items--item__desc">
            <div dangerouslySetInnerHTML={{ __html: allied.description }} />
          </div>
          {termLink &&
            <a onClick={(e) =>dataLayerAllied(e,allied.name)} href={termLink}>
              {termText}
            </a>
          }
        </>
      }
      {type === 3 &&
        <>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__image">
              <span style={{"backgroundImage": `url(/assets/images/${allied.image})`}}>
                <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
              </span>
            </div>
            <div className="mc-alliances-container__items--item__labels">
              <div className="mc-alliances-container__items--item__title">{allied.name}</div>
              <div className="mc-alliances-container__items--item__rule">{allied.rule}</div>
            </div>
          </div>
          <div className="mc-alliances-container__items--item__desc">
            <div dangerouslySetInnerHTML={{ __html: allied.description }} />
          </div>
          {termLink &&
            <a onClick={(e) =>dataLayerAllied(e,allied.name)} href={termLink}>
              {termText}
            </a>
          }
        </>
      }
      {type === 4 &&
        <>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__image">
              <span style={{"backgroundImage": `url(/assets/images/${allied.image})`}}>
                <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
              </span>
            </div>
            <div className="mc-alliances-container__items--item__labels">
              <div className="mc-alliances-container__items--item__title">{allied.name}</div>
              <div className="mc-alliances-container__items--item__rule">{allied.rule}</div>
              <div className="mc-alliances-container__items--item__desc">
                <div dangerouslySetInnerHTML={{ __html: allied.description }} />
              </div>
              {termLink &&
                <a onClick={(e) =>dataLayerAllied(e,allied.name)} href={termLink}>
                  {termText}
                </a>
              }
            </div>
          </div>
        </>
      }

      <CustomModal
        title="Términos y condiciones"
        desc={termsData}
        cta="Aceptar"
        icon={false}
        show={showOnModal} setShowModal={setShowOnModal}
        termsOnModal={true}
      />
    </div>
  )
}

export default AlliedBox